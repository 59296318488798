// IMPORTS
@import (reference) "../settings.less";

// GRID BANNER
.grid-banner {
    position: relative;
    background: @light-bg;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
        background: rgba(36,22,35, 0.9);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    img {
        .img-crop();
    }

    .banner-text-wrapper {
        padding: 8px;
        margin-inline: 32px;
        width: calc(100% - 64px);
        z-index: 2;
        display: flex;
        align-items: center;
        background: none;
        transition: all 0.2s ease-in-out;
        bottom: initial;
        top: 65%;

        > b {
            font-size: 14px;
            background: rgba(36,22,35, 0.9);
            width: 100%;
            padding: 8px;
            transition: all 0.2s ease-in-out;
        }

        p, i {
            opacity: 0;
            visibility: hidden;
            transition: all 0.1s ease-in-out;
        }

        > p {
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: block;
            display: -webkit-box !important;
            max-height: ~"calc(3 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }

        > i {
            font-style: normal;
            height: auto;
            padding: 8px 16px;
            margin-top: 16px;
        }
    }

    @media (min-width: 1280px) {
        &:hover {

            &:after {
                opacity: 1;
            }

            .banner-text-wrapper {
                top: 50%;
                transform: translateY(-50%);

                b {
                    background: transparent;
                }

                p, i {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

// BLOG BANNER
.blog-banner {

    figure {

        img {
            .img-crop();
        }
    }

    b {
        color: @header-color;

        @media(min-width: 1280px) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        color: @header-color;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        font-size: 14px;
        --fontSize: 14px;
        max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(3) {
            margin-top: -14px;
        }
    }

    i {
        height: 32px;
        font-style: normal;
        align-self: end;
        font-size: 10px;
    }
}